import type { PartnerBaseDTO } from '../Partner/PartnerBaseDTO'
import type { PhoneDTO } from '../common/PhoneDTO'
import type { TransportationBaseDTO } from '../Transportation/TransportationBaseDTO'
import type { VehicleBaseDTO } from '../Vehicle/VehicleBaseDTO'
import type { CarrierBaseDTO } from '../Carrier/CarrierBaseDTO'

export enum DriverStatusDTO {
  'ON_LINE' = 'ON_LINE',
  'NOT_ON_LINE' = 'NOT_ON_LINE'
}
export enum DriverActionDTO {
  'TAKE_OFF' = 'TAKE_OFF',
  'ASSIGN' = 'ASSIGN',
  'SPREADING_INCLUDE' = 'SPREADING_INCLUDE',
  'SPREADING_EXCLUDE' = 'SPREADING_EXCLUDE'
}

export type DriverDTO = {
  id: number
  firstName?: string
  secondName?: string
  lastName?: string
  status: DriverStatusDTO
  vehicle?: VehicleBaseDTO
  partner?: PartnerBaseDTO
  carrier?: CarrierBaseDTO
  transportation?: TransportationBaseDTO
  phone?: PhoneDTO
  lat?: number
  lng?: number
  lastActivityDate?: string
  availableActions: DriverActionDTO[]
}
