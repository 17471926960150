import 'vuetify/styles'
import YandexMaps from '@/components/icons/YandexMaps.vue'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
// Composables
import { createVuetify } from 'vuetify'
import { ru } from 'vuetify/locale'

const myTheme = {
  colors: {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  locale: {
    locale: 'ru',
    messages: { ru }
  },
  theme: {
    defaultTheme: 'myTheme',
    themes: {
      myTheme
    }
  },
  icons: {
    iconfont: 'mdiSvg',
    aliases,
    sets: {
      mdi
    },
    values: {
      yandexMaps: {
        component: YandexMaps
      }
    }
  }
})

// export default new Vuetify({
// 	lang: {
// 		locales: { ru },
// 		current: 'ru',
// 	},
// 	icons: {
// 		iconfont: 'mdiSvg',
// 		values: {
// 			yandexMaps: {
// 				component: YandexMaps
// 			}
// 		}
// 	},
// 	theme: {
// 		options: {
// 			minifyTheme,
// 			themeCache: {
// 				get: key => localStorage.getItem(key),
// 				set: (key, value) => localStorage.setItem(key, value),
// 			},
// 		},
// 		themes: {
// 			light: {
// 				primary: '#1976D2',
// 				secondary: '#424242',
// 				accent: '#82B1FF',
// 				error: '#FF5252',
// 				info: '#2196F3',
// 				success: '#4CAF50',
// 				warning: '#FFC107',
// 			}
// 		},
// 	}
// });
