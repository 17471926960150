import type { CarrierBase, DriverBase, GeoPoint, PartnerBase } from '.'
import type { FreightBase } from './Freight'

export type VehicleBase = {
  id: number
  number: string
}

export enum VehicleAction {
  'ASSIGN' = 'ASSIGN'
}

export enum OverweightType {
  WEIGHT_20 = 'WEIGHT_20',
  WEIGHT_26 = 'WEIGHT_26',
  WEIGHT_28 = 'WEIGHT_28',
  WEIGHT_30 = 'WEIGHT_30'
}

export enum VehicleStatus {
  ON_LINE = 'ON_LINE',
  NOT_ON_LINE = 'NOT_ON_LINE'
}
export enum FootsType {
  FOOTS_40 = 'FOOTS_40',
  FOOTS_40_20 = 'FOOTS_40_20',
  FOOTS_CONSTRUCTOR = 'FOOTS_CONSTRUCTOR'
}

export enum DeparturePermissionType {
  NONE = 'NONE',
  MKAD = 'MKAD',
  TTK = 'TTK',
  SK = 'SK'
}
export type Vehicle = {
  id: number
  number: string
  departurePermissionType: DeparturePermissionType
  overweightType: OverweightType
  footsType: FootsType
  status: VehicleStatus
  partner: PartnerBase
  transportation?: FreightBase
  /**
   * TODO: refactor it into a geo type
   */
  geo?: {
    lat: number
    lng: number
  }
  availableActions: VehicleAction[]
}

export type VehicleDetailed = {
  id: number
  number: string
  partner: PartnerBase
  carrier?: CarrierBase
  model?: string
  vin?: string
  departurePermissionType: DeparturePermissionType
  overweightType: OverweightType
  footsType: FootsType
  trailerNumber?: string
  trailerModel?: string
  status: VehicleStatus
  geo?: GeoPoint
  driver?: DriverBase
  transportation?: FreightBase
  availableActions: VehicleAction[]
}
