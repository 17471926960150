import { Permission } from '@/types/Permission'
import { PermissionDTO } from '@/types/api/requests/AccountInfo'

export const mapPermissionDTOtoPermission = (permissionDTO: PermissionDTO): Permission => {
  switch (permissionDTO) {
    case PermissionDTO.MAIN_SHOW:
      return Permission.MAIN_SHOW

    case PermissionDTO.PARTNER_SHOW:
      return Permission.PARTNER_SHOW

    case PermissionDTO.PARTNER_EDIT:
      return Permission.PARTNER_EDIT

    case PermissionDTO.DRIVER_SHOW:
      return Permission.DRIVER_SHOW

    case PermissionDTO.DRIVER_TAKE_OFF:
      return Permission.DRIVER_TAKE_OFF

    case PermissionDTO.DRIVER_SPREADING:
      return Permission.DRIVER_SPREADING

    case PermissionDTO.VEHICLE_SHOW:
      return Permission.VEHICLE_SHOW

    case PermissionDTO.TRANSPORTATION_SHOW:
      return Permission.TRANSPORTATION_SHOW

    case PermissionDTO.TRANSPORTATION_SHOW_ASSIGNED_BY:
      return Permission.TRANSPORTATION_SHOW_ASSIGNED_BY

    case PermissionDTO.TRANSPORTATION_SPREADING_ASSIGN:
      return Permission.TRANSPORTATION_SPREADING_ASSIGN

    case PermissionDTO.TRANSPORTATION_GENERATE_REPORT:
      return Permission.TRANSPORTATION_GENERATE_REPORT

    case PermissionDTO.TRANSPORTATION_CANCEL_DRIVER:
      return Permission.TRANSPORTATION_CANCEL_DRIVER

    case PermissionDTO.TRANSPORTATION_ASSIGN_DRIVER:
      return Permission.TRANSPORTATION_ASSIGN_DRIVER

    case PermissionDTO.TRANSPORTATION_APPROVE_OR_REJECT_DRIVER:
      return Permission.TRANSPORTATION_APPROVE_OR_REJECT_DRIVER

    case PermissionDTO.VEHICLE_REQUEST_SHOW:
      return Permission.VEHICLE_REQUEST_SHOW

    case PermissionDTO.VEHICLE_REQUEST_CREATE:
      return Permission.VEHICLE_REQUEST_CREATE

    case PermissionDTO.VEHICLE_REQUEST_RESOLVE:
      return Permission.VEHICLE_REQUEST_RESOLVE

    case PermissionDTO.DRIVER_REQUEST_SHOW:
      return Permission.DRIVER_REQUEST_SHOW

    case PermissionDTO.DRIVER_REQUEST_CREATE:
      return Permission.DRIVER_REQUEST_CREATE

    case PermissionDTO.DRIVER_REQUEST_RESOLVE:
      return Permission.DRIVER_REQUEST_RESOLVE

    case PermissionDTO.CARRIER_SHOW:
      return Permission.CARRIER_SHOW

    case PermissionDTO.CARRIER_REQUEST_SHOW:
      return Permission.CARRIER_REQUEST_SHOW

    case PermissionDTO.CARRIER_REQUEST_CREATE:
      return Permission.CARRIER_REQUEST_CREATE

    case PermissionDTO.CARRIER_REQUEST_RESOLVE:
      return Permission.CARRIER_REQUEST_RESOLVE

    case PermissionDTO.TRANSPORTATION_CLIENT_SHOW:
      return Permission.TRANSPORTATION_CLIENT_SHOW

    case PermissionDTO.TRANSPORTATION_DRIVER_ACTIVE_TR_SHOW:
      return Permission.TRANSPORTATION_DRIVER_ACTIVE_TR_SHOW
  }
}
