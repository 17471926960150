import { apiService } from '@/api/api'
import { mapPermissionDTOtoPermission } from '@/api/mappers/permission'
import type { Permission } from '@/types/Permission'
import type { GetAccountInfoResponse } from '@/types/api/requests'
import { AuthService } from '@/utils/auth.service'
import { setUser } from '@sentry/vue'
import { defineStore } from 'pinia'
import { useAppStore } from './app'

type Role = {
  code: string
  value: string
}

type UserState = {
  id?: number
  roles: Role[]
  permissions: Permission[]
  firstName?: string
  secondName?: string
  lastName?: string
  lastAction: Date
  authService: AuthService
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    roles: [],
    permissions: [],
    firstName: undefined,
    lastName: undefined,
    secondName: undefined,
    lastAction: new Date(),
    authService: new AuthService()
  }),
  getters: {
    isLoggedIn(state) {
      return state.permissions && state.permissions.length > 0
    },
    getPermissions(state) {
      return state.permissions
    },
    getUserName(state) {
      let result = ''
      if (state.firstName || state.secondName || state.lastName) {
        if (state.firstName) result += state.firstName.slice(0, 1) + '. '
        if (state.secondName) result += state.secondName.slice(0, 1) + '. '
        if (state.lastName) result += state.lastName
        return result
      } else return null
    },
    getUserFullName(state) {
      let result = ''
      if (state.firstName || state.secondName || state.lastName) {
        if (state.lastName) result += state.lastName + ' '
        if (state.firstName) result += state.firstName + ' '
        if (state.secondName) result += state.secondName
        return result
      } else return null
    },
    userPages(state) {
      const appStore = useAppStore()
      return appStore.getPages.filter(({ permissions: pagePermissions }) =>
        state.permissions.some((userPermission) => pagePermissions.includes(userPermission))
      )
    },

    can: (state) => (permission: Permission) => {
      return state.permissions.includes(permission)
    }
  },
  actions: {
    reset() {
      this.$reset()
    },
    /**
     * Used in router/functions.js.
     *
     * Before each router entry this function is called.
     * Therefore also called on initial app load and this way a token is either retrieved or a user is redirected to /auth page.
     */
    async initUser() {
      const accessToken = await this.authService.getValidAccessToken()
      if (accessToken === null) {
        return false
      }
      const res = await apiService.getAccountInfo()
      if (res) {
        this.ACCOUNT_INFO(res)
        const appStore = useAppStore()
        Promise.all([
          appStore.fetchAvailableCitizenshipsDictionary(),
          appStore.fetchAvailableVehiclesDictionary(),
          appStore.fetchAvailableTransporationStatusesDictionary()
        ])
        return true
      } else {
        return false
      }
    },
    ACCOUNT_INFO({
      id,
      roles,
      permissions,
      firstName,
      secondName,
      lastName
    }: GetAccountInfoResponse) {
      this.id = id
      this.roles = roles
      this.permissions = permissions.map(mapPermissionDTOtoPermission)
      this.firstName = firstName
      this.secondName = secondName
      this.lastName = lastName
      setUser({ id, username: `${firstName} ${lastName}` })
    },
    registerLastAction() {
      this.lastAction = new Date()
    }
  }
})
