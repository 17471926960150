import { createRouter, createWebHistory } from 'vue-router'
import functions from './functions'
import routes from './routes'
import { useAppStore } from '@/stores/app'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  const appStore = useAppStore()
  await appStore.setRouterLoadingState(true)
  functions.updatePageTitleAndMeta(document, to)
  const route = await functions.handleUnauthorizedAccess(to)
  await appStore.setRouterLoadingState(false)
  return route
})

export default router
