type RoleDTO = {
  code: string
  value: string
}

export enum PermissionDTO {
  MAIN_SHOW = 'SHOW_MAIN',
  PARTNER_SHOW = 'SHOW_PARTNERS',
  PARTNER_EDIT = 'EDIT_PARTNER',
  DRIVER_SHOW = 'SHOW_DRIVERS',
  DRIVER_TAKE_OFF = 'TAKE_OFF_DRIVERS',
  DRIVER_SPREADING = 'DRIVER_SPREADING',
  VEHICLE_SHOW = 'SHOW_VEHICLES',
  TRANSPORTATION_SHOW = 'SHOW_TRANSPORTATIONS',
  TRANSPORTATION_SHOW_ASSIGNED_BY = 'SHOW_TRANSPORTATION_ASSIGNED_BY',
  TRANSPORTATION_SPREADING_ASSIGN = 'TRANSPORTATION_SPREADING_ASSIGN',
  TRANSPORTATION_GENERATE_REPORT = 'GENERATE_TRANSPORTATIONS_REPORT',
  TRANSPORTATION_CANCEL_DRIVER = 'CANCEL_DRIVER_TRANSPORTATION',
  TRANSPORTATION_ASSIGN_DRIVER = 'ASSIGN_DRIVER_TRANSPORTATION',
  TRANSPORTATION_APPROVE_OR_REJECT_DRIVER = 'APPROVE_OR_REJECT_DRIVER_TRANSPORTATION',
  VEHICLE_REQUEST_SHOW = 'VEHICLE_REQUEST_SHOW',
  VEHICLE_REQUEST_CREATE = 'VEHICLE_REQUEST_CREATE',
  VEHICLE_REQUEST_RESOLVE = 'VEHICLE_REQUEST_RESOLVE',
  DRIVER_REQUEST_SHOW = 'DRIVER_REQUEST_SHOW',
  DRIVER_REQUEST_CREATE = 'DRIVER_REQUEST_CREATE',
  DRIVER_REQUEST_RESOLVE = 'DRIVER_REQUEST_RESOLVE',
  CARRIER_SHOW = 'CARRIER_SHOW',
  CARRIER_REQUEST_SHOW = 'CARRIER_REQUEST_SHOW',
  CARRIER_REQUEST_CREATE = 'CARRIER_REQUEST_CREATE',
  CARRIER_REQUEST_RESOLVE = 'CARRIER_REQUEST_RESOLVE',
  TRANSPORTATION_CLIENT_SHOW = 'TRANSPORTATION_CLIENT_SHOW',
  TRANSPORTATION_DRIVER_ACTIVE_TR_SHOW = 'TRANSPORTATION_DRIVER_ACTIVE_TR_SHOW'
}

export type GetAccountInfoResponse = {
  id?: number
  roles: RoleDTO[]
  permissions: PermissionDTO[]
  firstName?: string
  secondName?: string
  lastName?: string
}
