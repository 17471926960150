import { Mask } from 'maska'

export function mapFootsType(type) {
  switch (type) {
    case 'FOOTS_40':
      return '40'
    case 'FOOTS_40_20':
      return '40/20'
    case 'FOOTS_CONSTRUCTOR':
      return 'Конструктор'
    default:
      return type
  }
}

export function mapOverWeightType(type) {
  switch (type) {
    case 'WEIGHT_20':
      return 20
    case 'WEIGHT_26':
      return 26
    case 'WEIGHT_28':
      return 28
    case 'WEIGHT_30':
      return 30
    default:
      return type
  }
}

export function mapPermissionType(type) {
  switch (type) {
    case 'NONE':
      return 'Нет'
    case 'MKAD':
      return 'МКАД'
    case 'TTK':
      return 'ТТК'
    case 'SK':
      return 'CK'
    default:
      return type
  }
}

function formatDateShort(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [day, month, year].join('.')
}

const localeDateTimeLong = {
  weekday: 'long',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}

const localeDateTimeMedium = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}

export function formatDate(date, format = 'short') {
  if (format === 'short') return formatDateShort(date)
  if (format === 'medium') return new Date(date).toLocaleDateString('ru-RU', localeDateTimeMedium)
  return new Date(date).toLocaleDateString('ru-RU', localeDateTimeLong)
}

export const phoneMask = new Mask({ mask: ['8 (###) ###-##-##'] })

export function getErrorMessage(error) {
  switch (error.code) {
    case 2001:
      return 'Автомобиль с таким номером уже существует'
    case 2002:
      return 'Автомобиль с таким VIN уже существует'
    case 2003:
      return 'Водитель с таким номером телефона уже существует'
    case 404:
    case 405:
      return 'Произошла ошибка, попробуйте обновить страницу'
    case 500:
    default:
      return 'Произошла ошибка сервера, обновите страницу или свяжитесь с поддержкой'
  }
}

export const debounce = (fn, delay) => {
  let timeout

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
