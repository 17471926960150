import {
  DriverAction,
  type Driver,
  type DriverDetailed,
  DriverStatus,
  DocumentType,
  DriverSpreadingStatus
} from '@/types'
import { DriverActionDTO, type DriverDTO, DriverStatusDTO } from '@/types/api/Driver/DriverDTO'
import { getDriverFullName } from '@/utils/helpers'
import { mapFreightStatus } from './freight'
import {
  DriverSpreadingStatusDTO,
  type DriverDetailedDTO
} from '@/types/api/Driver/DriverDetailedDTO'
import { DocumentTypeDTO } from '@/types/api/Driver/DocumentTypeDTO'

export const mapDocumentType = (documentType: DocumentTypeDTO): DocumentType => {
  switch (documentType) {
    case DocumentTypeDTO.RUSSIAN_PASSPORT:
      return DocumentType.RUSSIAN_PASSPORT
    case DocumentTypeDTO.FOREIGN_PASSPORT:
      return DocumentType.FOREIGN_PASSPORT
  }
}

export const mapDriverStatus = (driverStatusDTO: DriverStatusDTO): DriverStatus => {
  switch (driverStatusDTO) {
    case DriverStatusDTO.ON_LINE:
      return DriverStatus.ON_LINE
    case DriverStatusDTO.NOT_ON_LINE:
      return DriverStatus.NOT_ON_LINE
  }
}

export const mapDriverAction = (driverActionDTO: DriverActionDTO): DriverAction => {
  switch (driverActionDTO) {
    case DriverActionDTO.TAKE_OFF:
      return DriverAction.TAKE_OFF

    case DriverActionDTO.ASSIGN:
      return DriverAction.ASSIGN

    case DriverActionDTO.SPREADING_EXCLUDE:
      return DriverAction.SPREADING_EXCLUDE

    case DriverActionDTO.SPREADING_INCLUDE:
      return DriverAction.SPREADING_INCLUDE
  }
}

export const mapDriverSpreadingStatus = (
  driverSpreadingStatusDTO: DriverSpreadingStatusDTO
): DriverSpreadingStatus => {
  switch (driverSpreadingStatusDTO) {
    case DriverSpreadingStatusDTO.ACTIVE:
      return DriverSpreadingStatus.ACTIVE

    case DriverSpreadingStatusDTO.EXCLUDED:
      return DriverSpreadingStatus.EXCLUDED

    case DriverSpreadingStatusDTO.INACTIVE:
      return DriverSpreadingStatus.INACTIVE
  }
}

export const mapDriverDTOToDriver = (driverDTO: DriverDTO): Driver => {
  let freight = undefined
  if (driverDTO.transportation) {
    freight = {
      id: driverDTO.transportation.id,
      status: mapFreightStatus(driverDTO.transportation.status)
    }
  }
  return {
    id: driverDTO.id,
    name: getDriverFullName(driverDTO),
    phone: driverDTO.phone,
    freight: freight,
    geo: {
      lat: driverDTO.lat,
      lng: driverDTO.lng
    },
    partner: driverDTO.partner,
    carrier: driverDTO.carrier,
    status: mapDriverStatus(driverDTO.status),
    lastActivityDate: driverDTO.lastActivityDate,
    vehicle: driverDTO.vehicle,
    availableActions: driverDTO.availableActions.map(mapDriverAction)
  }
}

export const mapDriverDetailedDTOToDriverDetailed = (
  driverDetailedDTO: DriverDetailedDTO
): DriverDetailed => {
  let passport = undefined
  if (
    driverDetailedDTO.passportSeries &&
    driverDetailedDTO.passportNumber &&
    driverDetailedDTO.passportIssuedBy &&
    driverDetailedDTO.passportIssuedAt
  ) {
    passport = {
      series: driverDetailedDTO.passportSeries,
      number: driverDetailedDTO.passportNumber,
      issuedBy: driverDetailedDTO.passportIssuedBy,
      issuedAt: driverDetailedDTO.passportIssuedAt,
      passportRegistrationAddress: driverDetailedDTO.passportRegistrationAddress
    }
  }

  let freight = undefined
  if (driverDetailedDTO.transportation?.id && driverDetailedDTO.transportation?.status) {
    freight = {
      id: driverDetailedDTO.transportation?.id,
      status: mapFreightStatus(driverDetailedDTO.transportation?.status)
    }
  }

  let geo = undefined
  if (driverDetailedDTO.lat && driverDetailedDTO.lng) {
    geo = {
      lat: driverDetailedDTO.lat,
      lng: driverDetailedDTO.lng
    }
  }
  return {
    id: driverDetailedDTO.id,
    name: getDriverFullName(driverDetailedDTO),
    phone: driverDetailedDTO.phone,
    citizenship: driverDetailedDTO.citizenship,
    documentType: driverDetailedDTO.documentType
      ? mapDocumentType(driverDetailedDTO.documentType)
      : undefined,
    passport: passport,
    inn: driverDetailedDTO.inn,
    status: mapDriverStatus(driverDetailedDTO.status),
    partner: driverDetailedDTO.partner,
    vehicle: driverDetailedDTO.vehicle,
    carrier: driverDetailedDTO.carrier,
    freight: freight,
    geo: geo,
    lastActivityDate: driverDetailedDTO.lastActivityDate,
    availableActions: driverDetailedDTO.availableActions.map(mapDriverAction),
    spreadingStatus: mapDriverSpreadingStatus(driverDetailedDTO.spreadingStatus),
    driverLicenceNumber: driverDetailedDTO.driverLicenceNumber
  }
}
