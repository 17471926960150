import type {
  DeparturePermissionTypeDTO,
  FootsTypeDTO,
  OverweightTypeDTO,
  VehicleActionDTO,
  VehicleDTO,
  VehicleStatusDTO
} from '@/types/api/Vehicle/VehicleDTO'
import {
  DeparturePermissionType,
  FootsType,
  OverweightType,
  VehicleStatus,
  type Vehicle,
  VehicleAction,
  type VehicleDetailed
} from '@/types/Vehicle'
import { mapFreightStatus } from './freight'
import type { VehicleDetailedDTO } from '@/types/api/Vehicle/VehicleDetailedDTO'

const mapOverweightType = (overweightType: OverweightTypeDTO): OverweightType => {
  switch (overweightType) {
    case 'WEIGHT_20':
      return OverweightType.WEIGHT_20
    case 'WEIGHT_26':
      return OverweightType.WEIGHT_26
    case 'WEIGHT_28':
      return OverweightType.WEIGHT_28
    case 'WEIGHT_30':
      return OverweightType.WEIGHT_30
    /**
     * TODO find a better way of a default return
     */
    default:
      return OverweightType.WEIGHT_20
  }
}

const mapVehicleStatus = (status: VehicleStatusDTO): VehicleStatus => {
  switch (status) {
    case 'ON_LINE':
      return VehicleStatus.ON_LINE
    case 'NOT_ON_LINE':
      return VehicleStatus.NOT_ON_LINE
    /**
     * TODO find a better way of a default return
     */
    default:
      return VehicleStatus.NOT_ON_LINE
  }
}

const mapFootsType = (footsType: FootsTypeDTO): FootsType => {
  switch (footsType) {
    case 'FOOTS_40':
      return FootsType.FOOTS_40
    case 'FOOTS_40_20':
      return FootsType.FOOTS_40_20
    case 'FOOTS_CONSTRUCTOR':
      return FootsType.FOOTS_CONSTRUCTOR
    /**
     * TODO find a better way of a default return
     */
    default:
      return FootsType.FOOTS_40
  }
}

const mapDeparturePermissionType = (
  departurePermissionType: DeparturePermissionTypeDTO
): DeparturePermissionType => {
  switch (departurePermissionType) {
    case 'NONE':
      return DeparturePermissionType.NONE
    case 'MKAD':
      return DeparturePermissionType.MKAD
    case 'TTK':
      return DeparturePermissionType.TTK
    case 'SK':
      return DeparturePermissionType.SK
    /**
     * TODO find a better way of a default return
     */
    default:
      return DeparturePermissionType.NONE
  }
}

const mapVehicleAction = (action: VehicleActionDTO): VehicleAction => {
  switch (action) {
    case 'ASSIGN':
      return VehicleAction.ASSIGN
    default:
      return VehicleAction.ASSIGN
  }
}

export const mapVehicleDTOToVehicle = (vehicleDTO: VehicleDTO): Vehicle => {
  let geo = undefined
  if (vehicleDTO.lat && vehicleDTO.lng) {
    geo = {
      lat: vehicleDTO.lat,
      lng: vehicleDTO.lng
    }
  }

  let transportation = undefined
  if (vehicleDTO.transportation) {
    transportation = {
      id: vehicleDTO.transportation.id,
      status: mapFreightStatus(vehicleDTO.transportation.status)
    }
  }
  return {
    id: vehicleDTO.id,
    number: vehicleDTO.number,
    partner: vehicleDTO.partner,
    departurePermissionType: mapDeparturePermissionType(vehicleDTO.departurePermissionType),
    overweightType: mapOverweightType(vehicleDTO.overweightType),
    footsType: mapFootsType(vehicleDTO.footsType),
    status: mapVehicleStatus(vehicleDTO.status),
    geo: geo,
    transportation: transportation,
    availableActions: vehicleDTO.availableActions.map(mapVehicleAction)
  }
}

export const mapVehicleDetailedDTOToVehicleDetailed = (
  vehicleDetailedDTO: VehicleDetailedDTO
): VehicleDetailed => {
  let geo = undefined
  if (vehicleDetailedDTO.lat && vehicleDetailedDTO.lng) {
    geo = {
      lat: vehicleDetailedDTO.lat,
      lng: vehicleDetailedDTO.lng
    }
  }
  let transportation = undefined
  if (vehicleDetailedDTO.transportation) {
    transportation = {
      id: vehicleDetailedDTO.transportation.id,
      status: mapFreightStatus(vehicleDetailedDTO.transportation.status)
    }
  }
  return {
    id: vehicleDetailedDTO.id,
    number: vehicleDetailedDTO.number,
    status: mapVehicleStatus(vehicleDetailedDTO.status),
    partner: vehicleDetailedDTO.partner,
    departurePermissionType: mapDeparturePermissionType(vehicleDetailedDTO.departurePermissionType),
    overweightType: mapOverweightType(vehicleDetailedDTO.overweightType),
    footsType: mapFootsType(vehicleDetailedDTO.footsType),
    vin: vehicleDetailedDTO.vin,
    geo: geo,
    transportation: transportation,
    availableActions: vehicleDetailedDTO.availableActions.map(mapVehicleAction),
    driver: vehicleDetailedDTO.driver,
    model: vehicleDetailedDTO.model,
    trailerModel: vehicleDetailedDTO.trailerModel,
    trailerNumber: vehicleDetailedDTO.trailerNumber,
    carrier: vehicleDetailedDTO.carrier
  }
}
