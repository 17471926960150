<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1a9.002 9.002 0 0 0-6.366 15.362c1.63 1.63
			5.466 3.988 5.693 6.465.034.37.303.673.673.673.37
			0 .64-.303.673-.673.227-2.477 4.06-4.831 5.689-6.46A9.002
			9.002 0 0 0 12 1zm0 12.079a3.079 3.079 0 1 1 0-6.158
			3.079 3.079 0 0 1 0 6.158z"
      fill="currentColor"
    />
  </svg>
</template>
