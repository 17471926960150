import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    status: false,
    message: '',
    color: 'error'
  }),
  actions: {
    reset() {
      this.$reset()
    },
    set(message) {
      this.openSnackbar({
        color: 'error',
        message: message ? message : 'Try again'
      })
    },
    push(message) {
      this.openSnackbar({
        color: 'info',
        message: message ? message : 'New notification'
      })
    },
    openSnackbar({ color, message }) {
      this.SET_SNACKBAR(false)
      this.SET_SNACKBAR_MESSAGE(message)
      this.SET_SNACKBAR_COLOR(color)
      setTimeout(() => {
        this.SET_SNACKBAR(true)
      }, 10)
    },
    SET_SNACKBAR(value) {
      this.status = value
    },
    SET_SNACKBAR_MESSAGE(value) {
      this.message = value
    },
    SET_SNACKBAR_COLOR(value) {
      this.color = value
    }
  }
})
