import { Permission } from '@/types/Permission'
import type { RouteRecordRaw } from 'vue-router'
const title = 'Chombo - '

const routes: readonly RouteRecordRaw[] = [
  {
    path: '/drivers',
    name: 'Drivers',
    components: {
      appbar: () => import('@/components/Bars/AppBar.vue'),
      navbar: () => import('@/components/Bars/NavBar.vue'),
      main: () => import('@/views/Drivers.vue')
    },
    meta: {
      public: false,
      title: title + 'Водители',
      permissions: [Permission.DRIVER_SHOW, Permission.DRIVER_REQUEST_SHOW]
    },
    children: [
      {
        path: ':id',
        name: 'DriverDetails',
        components: {}
      }
    ]
  },
  {
    path: '/partners',
    name: 'Partners',
    components: {
      appbar: () => import('@/components/Bars/AppBar.vue'),
      navbar: () => import('@/components/Bars/NavBar.vue'),
      main: () => import('@/views/Partners.vue')
    },
    meta: {
      public: false,
      title: title + 'Партнеры',
      permissions: [Permission.PARTNER_SHOW]
    }
  },

  {
    path: '/carriers',
    children: [
      {
        name: 'Carriers',
        path: '',
        components: {
          appbar: () => import('@/components/Bars/AppBar.vue'),
          navbar: () => import('@/components/Bars/NavBar.vue'),
          main: () => import('@/views/CarriersView.vue'),
          table: () => import('@/components/Domain/Carriers/Tables/OverviewTable/OverviewTable.vue')
        },
        children: [
          {
            path: ':id',
            name: 'CarrierDetails',
            component: () => import('@/components/Domain/Carriers/CarrierDetails.vue')
          }
        ]
      },

      {
        path: 'requests',
        name: 'CarrierRequests',
        components: {
          appbar: () => import('@/components/Bars/AppBar.vue'),
          navbar: () => import('@/components/Bars/NavBar.vue'),
          main: () => import('@/views/CarriersView.vue'),
          table: () => import('@/components/Domain/Carriers/Tables/RequestsTable/RequestsTable.vue')
        },
        children: [
          {
            name: 'CarrierRequestDetails',
            path: ':id',
            component: () =>
              import('@/components/Domain/Carriers/CarrierRequestCard/CarrierRequestCard.vue')
          },
          {
            name: 'NewCarrierRequest',
            path: 'new',
            component: () =>
              import('@/components/Domain/Carriers/CarrierRequestModal/CarrierRequestModal.vue')
          }
        ]
      }
    ],
    meta: {
      public: false,
      title: title + 'Перевозчики',
      permissions: [Permission.CARRIER_SHOW]
    }
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    components: {
      appbar: () => import('@/components/Bars/AppBar.vue'),
      navbar: () => import('@/components/Bars/NavBar.vue'),
      main: () => import('@/views/Vehicles.vue')
    },
    children: [
      {
        path: ':id',
        name: 'VehicleDetails',
        components: {}
      }
    ],
    meta: {
      public: false,
      title: title + 'Автомобили',
      permissions: [Permission.VEHICLE_SHOW, Permission.VEHICLE_REQUEST_SHOW]
    }
  },
  {
    path: '/freights',
    name: 'Freights',
    components: {
      appbar: () => import('@/components/Bars/AppBar.vue'),
      navbar: () => import('@/components/Bars/NavBar.vue'),
      main: () => import('@/views/Freights.vue')
    },
    meta: {
      public: false,
      title: title + 'Грузоперевозки',
      permissions: [Permission.TRANSPORTATION_SHOW]
    },
    children: [
      {
        path: ':id',
        name: 'FreightDetails',
        components: {}
      }
    ]
  },
  {
    path: '/auth',
    name: 'SignIn',
    components: {
      main: () => import('@/views/SignIn.vue')
    },
    meta: {
      public: true,
      title: title + 'Вход',
      permissions: []
    }
  },
  {
    path: '/',
    name: 'Main',
    components: {
      appbar: () => import('@/components/Bars/AppBar.vue'),
      navbar: () => import('@/components/Bars/NavBar.vue'),
      main: () => import('@/views/Main.vue')
    },
    meta: {
      public: false,
      title: title + 'Главная',
      permissions: [Permission.MAIN_SHOW]
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    components: {
      main: () => import('@/views/NotFound.vue')
    },
    meta: {
      public: true,
      title: title + '404',
      permissions: []
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

export default routes
