/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

import { createPinia } from 'pinia'
import { PiniaLogger } from 'pinia-logger'
import vuetify from './vuetify'
import * as Sentry from '@sentry/vue'

const pinia = createPinia()
pinia.use(
  PiniaLogger({
    expanded: true,
    disabled: true
  })
)

export default pinia

import router from '../router'

export function registerPlugins(app) {
  app.use(vuetify).use(pinia).use(router)
  if (import.meta.env.VITE_ENV !== 'local')
    Sentry.init({
      environment: import.meta.env.VITE_ENV,
      app,
      dsn: import.meta.env.VITE_SENTRY_CONFIG_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true
        })
      ],
      tracePropagationTargets: [import.meta.env.VITE_VUE_APP_API_URL],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}
