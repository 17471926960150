import { useUserStore } from '@/stores/user'

export default {
  updatePageTitleAndMeta(document, to) {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title)
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags)
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
      el.parentNode.removeChild(el)
    )
    if (!nearestWithMeta) return
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement('meta')
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key])
        })
        tag.setAttribute('data-vue-router-controlled', '')
        return tag
      })
      .forEach((tag) => document.head.appendChild(tag))
  },

  handleUnauthorizedAccess: async function (to) {
    const userStore = useUserStore()

    if (!userStore.isLoggedIn) {
      if (to.path === '/auth') {
        return true
      }
      const isUserInitialized = await userStore.initUser()
      if (!isUserInitialized) {
        return '/auth'
      }
      return true
    }
    if (to.meta.public) {
      return true
    } else if (
      userStore.getPermissions.some((permission) => to.meta.permissions.includes(permission))
    ) {
      return true
    } else {
      return '/'
    }
  }
}
