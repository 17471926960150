<template>
  <v-snackbar
    v-model="status"
    :color="snackbarStore.color"
    :timeout="12000"
    :vertical="!smAndUp"
    location="top"
  >
    {{ snackbarStore.message }}
    <template #actions>
      <v-btn variant="text" :color="buttonColor" @click="status = false"> Закрыть </v-btn>
    </template>
  </v-snackbar>
</template>
<script setup>
import { useSnackbarStore } from '@/stores/snackbar'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'
const snackbarStore = useSnackbarStore()
const { smAndUp } = useDisplay()

const status = computed({
  get: () => snackbarStore.status,
  set: (value) => snackbarStore.SET_SNACKBAR(value)
})

const buttonColor = computed(() => {
  if (snackbarStore.color === 'red') return 'white'
  return 'red'
})
</script>
