import { apiService } from '@/api/api'
import {
  mapVehicleDTOToVehicle,
  mapVehicleDetailedDTOToVehicleDetailed
} from '@/api/mappers/vehicle'
import { VehicleAction, type Vehicle, type VehicleStatus, type VehiclesStoreState } from '@/types'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useSnackbarStore } from './snackbar'

export const useVehiclesStore = defineStore('vehiclesStore', {
  state: (): VehiclesStoreState => ({
    data: [],
    filterByPartner: [],
    filterByOnLineStatus: [],
    loading: false,
    vehicleDetailed: undefined
  }),

  getters: {
    isFiltered(state) {
      let count = 0
      count += state.filterByPartner.length > 0 ? 1 : 0
      count += state.filterByOnLineStatus.length > 0 ? 1 : 0
      return count
    },
    filtered(state): Vehicle[] {
      let result = [...state.data]
      if (state.filterByPartner.length > 0) {
        result = result.filter(({ partner }) => {
          return partner.id && state.filterByPartner.includes(partner.id)
        })
      }
      if (state.filterByOnLineStatus.length > 0) {
        result = result.filter(({ status }) => {
          return status && state.filterByOnLineStatus.includes(status)
        })
      }
      return result
    },
    getAssignableVehicles(state) {
      return state.data.filter((vehicle) => vehicle.availableActions.includes(VehicleAction.ASSIGN))
    },
    getTrucksForRequest(state) {
      return state.data.map((vehicle) => ({ id: vehicle.id, number: vehicle.number }))
    },
    getTrucksForPartner: (state) => (partnerName: string) => {
      return state.data.filter((vehicle) => vehicle.partner.name === partnerName)
    },
    getTruckById: (state) => (id: number) => {
      return state.data.find((vehicle) => vehicle.id === id)
    },
    getTruckByNumber: (state) => (numberToFind: string) => {
      return state.data.find((vehicle) => vehicle.number === numberToFind)
    }
  },

  actions: {
    reset() {
      this.$reset()
    },
    // TODO add silently option
    async fetch() {
      const data = await apiService.getVehicles()
      this.data = data.map(mapVehicleDTOToVehicle)
    },
    async fetchVehicleDetailed(id: number) {
      const data = await apiService.getVehicledDetailed(id)
      this.vehicleDetailed = mapVehicleDetailedDTOToVehicleDetailed(data)
    },
    async showVehicleDetails(id: number) {
      if (this.vehicleDetailed !== undefined) {
        const snackbarStore = useSnackbarStore()
        snackbarStore.openSnackbar({
          color: 'secondary',
          message: 'Карточка автомобиля уже открыта'
        })
        return
      }
      await this.fetchVehicleDetailed(id)
      const appStore = useAppStore()
      appStore.openVehicleDetails()
    },
    async refetchVehicle(id: number) {
      if (this.data.length === 0) return
      const index = this.data.findIndex((vehicle) => vehicle.id === id)
      if (index !== -1) {
        const vehicle = await apiService.getVehicle(id)
        this.data[index] = mapVehicleDTOToVehicle(vehicle)
      }
    },
    closeVehicleDetails() {
      const appStore = useAppStore()
      appStore.closeVehicleDetails()
      // prevent modal from jumping
      setTimeout(() => {
        this.vehicleDetailed = undefined
      }, 300)
    },
    setFilterByPartner(value: number[]) {
      this.filterByPartner = value
    },
    setFilterByOnLineStatus(value: VehicleStatus[]) {
      this.filterByOnLineStatus = value
    },
    clearFilter() {
      this.filterByPartner = []
      this.filterByOnLineStatus = []
    },
    setLoadingState(loadingState: boolean) {
      this.loading = loadingState
    }
  }
})
