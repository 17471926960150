import type { AvailableCitizenship, CarrierBase, FreightBase, PartnerBase, VehicleBase } from '.'
import { type Phone } from './Phone'
export type DriverBase = {
  id: number
  phoneNum: string
  phone: Phone
  firstName?: string
  secondName?: string
  lastName?: string
}

export enum DriverAction {
  'TAKE_OFF' = 'TAKE_OFF',
  'ASSIGN' = 'ASSIGN',
  'SPREADING_INCLUDE' = 'SPREADING_INCLUDE',
  'SPREADING_EXCLUDE' = 'SPREADING_EXCLUDE'
}
export enum DriverStatus {
  'ON_LINE' = 'ON_LINE',
  'NOT_ON_LINE' = 'NOT_ON_LINE'
}

export enum DriverSpreadingStatus {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'EXCLUDED' = 'EXCLUDED'
}

export type Driver = {
  id: number
  name: string
  phone?: Phone
  status: DriverStatus
  vehicle?: VehicleBase
  partner?: PartnerBase
  carrier?: CarrierBase
  geo: {
    lat?: number
    lng?: number
  }
  freight?: FreightBase
  lastActivityDate?: string
  availableActions: DriverAction[]
}

export enum DocumentType {
  RUSSIAN_PASSPORT = 'RUSSIAN_PASSPORT',
  FOREIGN_PASSPORT = 'FOREIGN_PASSPORT'
}

export type Passport = {
  series: string
  number: string
  issuedBy: string
  issuedAt: string
  /** TODO: remove optional flag when data is migrated */
  passportRegistrationAddress?: string
}

export type DriverDetailed = {
  id: number
  name: string
  phone?: Phone
  citizenship?: AvailableCitizenship
  documentType?: DocumentType
  passport?: Passport
  driverLicenceNumber?: string
  inn?: string
  status: DriverStatus
  partner?: PartnerBase
  vehicle?: VehicleBase
  carrier?: CarrierBase
  freight?: FreightBase
  geo?: {
    lat: number
    lng: number
  }

  lastActivityDate?: string
  availableActions: DriverAction[]
  spreadingStatus: DriverSpreadingStatus
}
