import axios from 'axios'
import { AuthService } from '@/utils/auth.service'

const DocAPI = axios.create({
  baseURL: import.meta.env.VITE_VUE_APP_API_URL
})
const authService = new AuthService()

DocAPI.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = await authService.getValidAccessToken()
    return config
  },
  function (error) {
    console.log('Rejected Request:')
    console.log(error)
  }
)

DocAPI.interceptors.response.use(async (response) => {
  if (response.status === 200) {
    const bp = response.data
    const blob = new Blob([bp], {
      type: response.headers['content-type']
    })
    const link = document.createElement('a')
    const URL = window.URL || window.webkitURL
    const downloadUrl = URL.createObjectURL(blob)
    link.href = downloadUrl
    link.style.display = 'none'
    setTimeout(function () {
      document.body.removeChild(link)
      window.URL.revokeObjectURL(downloadUrl)
    }, 100)
    return link
  } else {
    return undefined
  }
})
export default DocAPI
