<template>
  <v-app v-resize="onResize">
    <router-view name="appbar" />
    <router-view name="navbar" />
    <v-main>
      <Snackbar />
      <router-view name="main" />
      <router-view name="table" />
      <router-view name="detail-card" />
      <DriverActionsModal />
      <DriverDetails />
      <FreightDetails />
      <VehicleDetails />
      <CarrierDetails />
    </v-main>
    <v-overlay v-if="appStore.isRouterLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-app>
</template>

<script setup lang="ts">
import Snackbar from '@/components/Snackbar.vue'
import { defineAsyncComponent, onMounted } from 'vue'
import { useAppStore } from './stores/app'
const DriverActionsModal = defineAsyncComponent(
  () => import('@/components/Domain/Drivers/DriverActionsModal/DriverActionsModal.vue')
)
const DriverDetails = defineAsyncComponent(
  () => import('@/components/Domain/Drivers/DriverDetails.vue')
)
const FreightDetails = defineAsyncComponent(
  () => import('@/components/Domain/Freight/FreightDetails.vue')
)

const VehicleDetails = defineAsyncComponent(
  () => import('@/components/Domain/Vehicles/VehicleDetails.vue')
)

const CarrierDetails = defineAsyncComponent(
  () => import('@/components/Domain/Carriers/CarrierDetails.vue')
)

const appStore = useAppStore()

const onResize = () => {
  appStore.setWidth(window.innerWidth)
}

onMounted(() => {
  onResize()
})
</script>

<style lang="scss">
a {
  color: #1976d2;
}
:deep(.v-card-text) {
  padding: 0;
}
</style>
