export class StorageService {
  storage: Storage = localStorage
  accessTokenKey = 'accessToken'
  refreshTokenKey = 'refreshToken'
  accessTokenExpirationKey = 'accessTokenExpiresAt'
  refreshTokenExpirationDateKey = 'refreshTokenExpiresAt'
  phoneKey = 'phone'
  selectedTrucksTableKey = 'selectedTrucksTable'
  selectedDriversTableKey = 'selectedDriversTable'
  constructor() {
    try {
      localStorage.setItem('test', 'test')
      localStorage.removeItem('test')
    } catch (e) {
      //  We're going to detect this and just silently drop any calls to setItem
      //  to avoid the entire page breaking, without having to do a check at each usage of Storage.
      // Storage.prototype._setItem = Storage.prototype.setItem;
      // Storage.prototype.setItem = function() {};

      alert(
        `Your web browser does not support storing settings locally.  
          Some settings may not save or some features may not work properly for you.  
          If you are using "Private Browsing Mode",  
          you can disable it and try to access this website again.`
      )
    }
  }

  getAccessToken(): string | null {
    return this.storage.getItem(this.accessTokenKey)
  }
  getRefreshToken(): string | null {
    return this.storage.getItem(this.refreshTokenKey)
  }
  getAccessTokenExpirationDate(): string | null {
    return this.storage.getItem(this.accessTokenExpirationKey)
  }
  getRefreshTokenExpirationDate(): string | null {
    return this.storage.getItem(this.refreshTokenExpirationDateKey)
  }
  getPhone(): string | null {
    return this.storage.getItem(this.phoneKey)
  }
  getSelectedTrucksTable(): number {
    const selectedTrucksTable = this.storage.getItem(this.selectedTrucksTableKey)
    if (selectedTrucksTable !== null) return Number(selectedTrucksTable)
    return 0
  }
  getSelectedDriversTable(): number {
    const selectedDriversTable = this.storage.getItem(this.selectedDriversTableKey)
    if (selectedDriversTable !== null) return Number(selectedDriversTable)
    return 0
  }

  setAccessToken(accessToken: string) {
    this.storage.setItem(this.accessTokenKey, accessToken)
  }
  setRefreshToken(refreshToken: string) {
    this.storage.setItem(this.refreshTokenKey, refreshToken)
  }
  setAccessTokenExpirationDate(accessTokenExpirationDate: number) {
    this.storage.setItem(this.accessTokenExpirationKey, accessTokenExpirationDate.toString())
  }
  setRefreshTokenExpirationDate(refreshTokenExpirationDate: number) {
    this.storage.setItem(this.refreshTokenExpirationDateKey, refreshTokenExpirationDate.toString())
  }
  setPhone(phone: string) {
    this.storage.setItem(this.phoneKey, phone)
  }

  setSelectedTrucksTable(selectedTrucksTable: number) {
    this.storage.setItem(this.selectedTrucksTableKey, selectedTrucksTable.toString())
  }

  setSelectedDriversTable(selectedDriversTable: number) {
    this.storage.setItem(this.selectedDriversTableKey, selectedDriversTable.toString())
  }

  // REMOVERS
  removeAccessToken() {
    this.storage.removeItem(this.accessTokenKey)
  }
  removeRefreshToken() {
    this.storage.removeItem(this.refreshTokenKey)
  }
  removeAccessExpirationDate() {
    this.storage.removeItem(this.accessTokenExpirationKey)
  }
  removeRefreshExpirationDate() {
    this.storage.removeItem(this.refreshTokenExpirationDateKey)
  }
  removePhone() {
    this.storage.removeItem(this.phoneKey)
  }
  removeSelectedTrucksTable() {
    this.storage.removeItem(this.selectedTrucksTableKey)
  }
  removeSelectedDriversTable() {
    this.storage.removeItem(this.selectedDriversTableKey)
  }

  clear() {
    this.removeAccessToken()
    this.removeRefreshToken()
    this.removeAccessExpirationDate()
    this.removeRefreshExpirationDate()
    this.removeSelectedDriversTable()
    this.removeSelectedTrucksTable()
  }
}

export default new StorageService()
