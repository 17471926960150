export enum Permission {
  MAIN_SHOW = 'SHOW_MAIN',
  PARTNER_SHOW = 'PARTNER_SHOW',
  PARTNER_EDIT = 'PARTNER_EDIT',
  DRIVER_SHOW = 'DRIVER_SHOW',
  DRIVER_TAKE_OFF = 'DRIVER_TAKE_OFF',
  DRIVER_SPREADING = 'DRIVER_SPREADING',
  VEHICLE_SHOW = 'VEHICLE_SHOW',
  TRANSPORTATION_SHOW = 'TRANSPORTATION_SHOW',
  TRANSPORTATION_SHOW_ASSIGNED_BY = 'TRANSPORTATION_SHOW_ASSIGNED_BY',
  TRANSPORTATION_SPREADING_ASSIGN = 'TRANSPORTATION_SPREADING_ASSIGN',
  TRANSPORTATION_GENERATE_REPORT = 'TRANSPORTATION_GENERATE_REPORT',
  TRANSPORTATION_CANCEL_DRIVER = 'TRANSPORTATION_CANCEL_DRIVER',
  TRANSPORTATION_ASSIGN_DRIVER = 'TRANSPORTATION_ASSIGN_DRIVER',
  TRANSPORTATION_APPROVE_OR_REJECT_DRIVER = 'TRANSPORTATION_APPROVE_OR_REJECT_DRIVER',
  VEHICLE_REQUEST_SHOW = 'VEHICLE_REQUEST_SHOW',
  VEHICLE_REQUEST_CREATE = 'VEHICLE_REQUEST_CREATE',
  VEHICLE_REQUEST_RESOLVE = 'VEHICLE_REQUEST_RESOLVE',
  DRIVER_REQUEST_SHOW = 'DRIVER_REQUEST_SHOW',
  DRIVER_REQUEST_CREATE = 'DRIVER_REQUEST_CREATE',
  DRIVER_REQUEST_RESOLVE = 'DRIVER_REQUEST_RESOLVE',
  CARRIER_SHOW = 'CARRIER_SHOW',
  CARRIER_REQUEST_SHOW = 'CARRIER_REQUEST_SHOW',
  CARRIER_REQUEST_CREATE = 'CARRIER_REQUEST_CREATE',
  CARRIER_REQUEST_RESOLVE = 'CARRIER_REQUEST_RESOLVE',
  TRANSPORTATION_CLIENT_SHOW = 'TRANSPORTATION_CLIENT_SHOW',
  TRANSPORTATION_DRIVER_ACTIVE_TR_SHOW = 'TRANSPORTATION_DRIVER_ACTIVE_TR_SHOW'
}
