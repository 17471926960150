import type { AccountBase } from './Account'
import type { PartnerBase } from './Partner'
import type { Phone } from './Phone'

export type CarrierBase = {
  id: number
  name: string
}

export type Carrier = {
  id: number
  partners: PartnerBase[]
  name: string
  inn: string
}

export type CarrierDetailed = {
  id: number
  partners: PartnerBase[]
  name: string
  inn: string
  headFirstName: string
  headSecondName: string
  headLastName: string
  headBirthday: string
  headPhone: Phone
}

export enum CarrierRequestType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
export enum CarrierRequestStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export type CarrierRequestPayload = {
  name: string
  inn: string
  headFirstName: string
  headSecondName: string
  headLastName: string
  headBirthday: string
  headPhone: Phone
}
export type CarrierRequestForm = {
  partnerId?: number
  carrierId?: number
  type: CarrierRequestType
  payload: CarrierRequestPayload
}

export type CarrierRequest = {
  id: number
  type: CarrierRequestType
  partner: PartnerBase
  author: AccountBase
  resolver?: AccountBase
  status: CarrierRequestStatus
  comment?: string
  payload?: CarrierRequestPayload
  carrier?: CarrierBase
  createdAt: string
}
