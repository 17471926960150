import axios, { type AxiosResponse } from 'axios'

import router from '@/router'
import type { AuthToken } from '@/types'
import { captureException } from '@sentry/vue'
import { defineStore, getActivePinia } from 'pinia'
import { StorageService } from './storage.service'

const API_URL = import.meta.env.VITE_VUE_APP_API_URL + 'auth/'
type AuthenticationData = {
  url: string
  data: {
    phone?: string
    code?: string
    refreshToken?: string
  }
}
export class AuthService {
  uid: string
  storageService = new StorageService()

  constructor() {
    this.uid = this.generateDeviceId()
  }
  generateDeviceId() {
    return window.navigator.userAgent.replace(/\D+/g, '')
  }

  getPhoneFromStorage() {
    return this.storageService.getPhone()
  }
  setPhoneToStorage(phone: string) {
    this.storageService.setPhone(phone)
  }
  removePhoneFromStorage() {
    this.storageService.removePhone()
  }

  setTokenData(response: AxiosResponse<AuthToken>) {
    if (response.data.accessToken) this.storageService.setAccessToken(response.data.accessToken)

    if (response.data.refreshToken) this.storageService.setRefreshToken(response.data.refreshToken)

    if (response.data.accessExpiresIn) {
      const expirationDate = Date.now() + response.data.accessExpiresIn - 5000
      this.storageService.setAccessTokenExpirationDate(expirationDate)
    }

    if (response.data.refreshExpiresIn) {
      const expirationDate = Date.now() + response.data.refreshExpiresIn - 5000
      this.storageService.setRefreshTokenExpirationDate(expirationDate)
    }
  }

  async getValidAccessToken() {
    if (this.isAccessTokenExpired()) {
      const { success } = await this.refreshTokenData()
      if (!success) {
        return null
      }
    }
    return this.storageService.getAccessToken()
  }

  isAccessTokenExpired() {
    const accessTokenExpirationDate = this.storageService.getAccessTokenExpirationDate()
    if (accessTokenExpirationDate === null) return true
    return Date.now() > Number(accessTokenExpirationDate)
  }

  async refreshTokenData() {
    const refreshToken = this.storageService.getRefreshToken()
    if (refreshToken !== null) {
      return await this.authenticate({
        url: API_URL + 'token/refresh',
        data: {
          refreshToken
        }
      })
    }

    return { success: false }
  }
  authenticate({ url, data }: AuthenticationData) {
    return axios
      .post(url, data, { headers: { DeviceId: this.uid } })
      .then((response) => {
        if (response.data.success) {
          this.setTokenData(response.data)
          return { success: true }
        } else {
          return { success: false }
        }
      })
      .catch((e) => {
        captureException(e)
        return { success: false }
      })
  }

  loginByPhone(phone: string, code: string) {
    return this.authenticate({
      url: API_URL + 'login/phone',
      data: { phone, code }
    })
  }

  getPhoneCode(phone: string) {
    return axios.post(API_URL + 'login/phone/code', {
      phone
    })
  }

  async logout() {
    axios.post(API_URL + 'logout', null, { headers: { DeviceId: this.uid } }) //server
    this.storageService.clear() //localstore
    localStorage.removeItem('selectedDriversTable')
    localStorage.removeItem('selectedTrucksTable')
    try {
      const activePinia = getActivePinia()
      if (activePinia) {
        Object.entries(activePinia.state.value).forEach(([storeName, state]) => {
          const storeDefinition = defineStore(storeName, state)
          const store = storeDefinition(activePinia)
          store.$reset()
        })
      }
    } catch (e) {
      console.info('user data not cleaned up')
    }
    await router.push('/auth')
  }
}
