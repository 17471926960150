import type { AvailableCitizenshipDTO } from '../common/AvailableCitizenshipDTO'
import type { DocumentTypeDTO } from './DocumentTypeDTO'
import type { DriverActionDTO, DriverStatusDTO } from './DriverDTO'
import type { PartnerBaseDTO } from '../Partner/PartnerBaseDTO'
import type { PhoneDTO } from '../common/PhoneDTO'
import type { TransportationBaseDTO } from '../Transportation/TransportationBaseDTO'
import type { VehicleBaseDTO } from '../Vehicle/VehicleBaseDTO'
import type { CarrierBaseDTO } from '../Carrier/CarrierBaseDTO'

export enum DriverSpreadingStatusDTO {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'EXCLUDED' = 'EXCLUDED'
}

export type DriverDetailedDTO = {
  id: number
  firstName?: string
  secondName?: string
  lastName: string
  phone?: PhoneDTO
  citizenship?: AvailableCitizenshipDTO
  documentType?: DocumentTypeDTO
  driverLicenceNumber?: string
  passportSeries?: string
  passportNumber?: string
  passportIssuedBy?: string
  passportIssuedAt?: string
  passportRegistrationAddress?: string
  inn?: string
  status: DriverStatusDTO
  partner?: PartnerBaseDTO
  carrier?: CarrierBaseDTO
  vehicle?: VehicleBaseDTO
  transportation?: TransportationBaseDTO
  lat?: number
  lng?: number
  lastActivityDate?: string
  availableActions: DriverActionDTO[]
  spreadingStatus: DriverSpreadingStatusDTO
}
